<template>
  <div id="dashboard">
    <div class="uk-container uk-container-large">
      <header class="uk-margin-top">
        <div class="uk-container uk-container-large">
          <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
            <div class="uk-navbar-left">
              <ul class="uk-navbar-nav">
                <div v-if="user">
                  {{ user.email }}
                </div>
              </ul>
            </div>
            <div class="uk-navbar-right">
              <ul class="uk-navbar-nav">
                <li>
                  <button class="uk-button uk-button-primary" @click="logout">
                    登出
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <!-- <ul class="uk-child-width-expand uk-margin-top" uk-tab>
        <li><a href="#">簡易聯絡表</a></li>
        <li><a href="#">聯絡表單</a></li>
      </ul> -->
      
      <!-- <ul class="uk-switcher uk-margin">
        <li>
          <SimpleContact />
        </li>
        <li>
          <ContactForm />
        </li>
      </ul> -->

      <div class="uk-margin">
        <ContactForm />
      </div>

    </div>
  </div>
</template>

<script>
import firebase from "firebase";

// import SimpleContact from "@/components/SimpleContact.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "檢視表單",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "",
      },
       {
        vmid: "keywords",
        name: "keywords",
        content: "",
      },
    ],
  },
  components: {
    // SimpleContact,
    ContactForm,
  },
  data() {
    return {
      user: [],
    };
  },
  created() {
    var self = this;
    firebase.auth().onAuthStateChanged(function (user) {
      self.user = user;
    });
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // this.$store.commit("setUser", null);
          alert("帳戶已登出");
          this.$router.push("/login");
        });
    },
  },
};
</script>