<template>
  <div id="ContactForm">
    <div class="uk-card uk-card-small uk-card-default uk-visible@m">
      <div class="uk-card-body">
        <table class="uk-table uk-table-middle uk-table-divider">
          <thead>
            <tr>
              <th class="uk-width-medium">時間</th>
              <th class="uk-width-small">姓名</th>
              <th class="uk-width-small">電話</th>
              <th class="uk-width-medium">融資金額</th>
              <th class="uk-width-small">申請種類</th>
              <th class="uk-width-small">填寫來源</th>
              <th class="uk-width-small"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in Contact" :key="index">
              <td>{{ item.date | date_format }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.phone }}</td>
              <td>
                <span v-if="item.amount == '0'">未選擇</span>
                <span v-if="item.amount == 'A1'">50萬(含)以下</span>
                <span v-if="item.amount == 'A2'">50萬以上~100萬(含)以下</span>
                <span v-if="item.amount == 'A3'">100萬以上~500萬(含)以下</span>
                <span v-if="item.amount == 'A4'">500萬以上</span>
                <!-- <span v-else>{{item.amount}}</span> -->
              </td>
              <td>
                <span v-if="item.category == '0'">未選擇</span>
                <span v-if="item.category == 'C1'">汽車</span>
                <span v-if="item.category == 'C2'">機車</span>
                <span v-if="item.category == 'C3'">房屋</span>
                <span v-if="item.category == 'C4'">萬物貸</span>
                <!-- <span v-else>{{item.category}}</span> -->
              </td>
              <td>{{ item.type }}</td>
              <td>
                <a
                  href="#"
                  :uk-toggle="'target: #modal-close-default-' + index"
                  class="uk-button uk-button-default"
                  >檢視</a
                >
              </td>
              <!-- modal  -->
              <div :id="'modal-close-default-' + index" uk-modal>
                <div class="uk-modal-dialog uk-modal-body">
                  <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                  ></button>
                  <h3>
                    {{ item.name }}
                    <small class="uk-text-small uk-text-muted">{{
                      item.date | date_format
                    }}</small>
                  </h3>
                  <div class="uk-margin">
                    <label class="uk-form-label">填寫來源:</label>
                    <div class="uk-form-controls">
                      {{ item.type }}
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">電話:</label>
                    <div class="uk-form-controls">
                      <a :href="'tel:' + item.phone">
                        {{ item.phone }}
                      </a>
                    </div>
                  </div>
                  <div v-if="item.email" class="uk-margin">
                    <label class="uk-form-label">電子信箱:</label>
                    <div class="uk-form-controls">
                      <span>
                        <a :href="'mailto:' + item.email">
                          {{ item.email }}
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">融資金額:</label>
                    <div class="uk-form-controls">
                      <span v-if="item.amount == '0'">未選擇</span>
                      <span v-if="item.amount == 'A1'">50萬(含)以下</span>
                      <span v-if="item.amount == 'A2'"
                        >50萬以上~100萬(含)以下</span
                      >
                      <span v-if="item.amount == 'A3'"
                        >100萬以上~500萬(含)以下</span
                      >
                      <span v-if="item.amount == 'A4'">500萬以上</span>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">申請種類:</label>
                    <div class="uk-form-controls">
                      <span v-if="item.category == '0'">未選擇</span>
                      <span v-if="item.category == 'C1'">汽車</span>
                      <span v-if="item.category == 'C2'">機車</span>
                      <span v-if="item.category == 'C3'">房屋</span>
                      <span v-if="item.category == 'C4'">萬物貸</span>
                    </div>
                  </div>
                  <div v-if="item.content" class="uk-margin">
                    <label class="uk-form-label">內容:</label>
                    <div class="uk-form-controls">
                      <div class="uk-background-muted uk-padding-small">
                        {{ item.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- modal end -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div uk-grid class="uk-child-width-1-1 uk-hidden@m">
      <div v-for="(item, index) in Contact" :key="index">
        <div class="uk-card uk-card-small uk-card-default">
          <div class="uk-card-body">
            <article class="uk-article uk-form-stacked">
              <h3>
                {{ item.name }}
                <small class="uk-text-small uk-text-muted">{{
                  item.date | date_format
                }}</small>
              </h3>
              <div class="uk-margin">
                <label class="uk-form-label">填寫來源:</label>
                <div class="uk-form-controls">
                  {{ item.type }}
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">電話:</label>
                <div class="uk-form-controls">
                  <a :href="'tel:' + item.phone">
                    {{ item.phone }}
                  </a>
                </div>
              </div>
              <div v-if="item.content" class="uk-margin">
                <label class="uk-form-label">電子信箱:</label>
                <div class="uk-form-controls">
                  <span>
                    <a :href="'mailto:' + item.email">
                      {{ item.email }}
                    </a>
                  </span>
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">融資金額:</label>
                <div class="uk-form-controls">
                  <span v-if="item.amount == '0'">未選擇</span>
                  <span v-if="item.amount == 'A1'">50萬(含)以下</span>
                  <span v-if="item.amount == 'A2'">50萬以上~100萬(含)以下</span>
                  <span v-if="item.amount == 'A3'"
                    >100萬以上~500萬(含)以下</span
                  >
                  <span v-if="item.amount == 'A4'">500萬以上</span>
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">申請種類:</label>
                <div class="uk-form-controls">
                  <span v-if="item.category == '0'">未選擇</span>
                  <span v-if="item.category == 'C1'">汽車</span>
                  <span v-if="item.category == 'C2'">機車</span>
                  <span v-if="item.category == 'C3'">房屋</span>
                  <span v-if="item.category == 'C4'">萬物貸</span>
                </div>
              </div>
              <div v-if="item.content" class="uk-margin">
                <label class="uk-form-label">內容:</label>
                <div class="uk-form-controls">
                  <div class="uk-background-muted uk-padding-small">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/main";
export default {
  data() {
    return {
      Contact: [],
    };
  },
  firestore: {
    Contact: db.collection("Contact").orderBy("date", "desc"),
  },
};
</script>